html {
	height: 100%;
}

body {
	display: flex;
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: sans-serif;
	background-color: #000000;
	overflow: hidden;
}
