.drawing {
	animation: fade-out 400ms linear 8s normal forwards;
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}
.stroke-1 {
	animation: stroke-animation-1 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-1 {
	0% {
		stroke-dashoffset: 2263.96;
	}
	27.27%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-2 {
	animation: stroke-animation-2 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-2 {
	0%, 13.63% {
		stroke-dashoffset: 995.95;
	}
	31.81%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-3 {
	animation: stroke-animation-3 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-3 {
	0%, 25% {
		stroke-dashoffset: 670.84;
	}
	40.91%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-4 {
	animation: stroke-animation-4 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-4 {
	0%, 34.09% {
		stroke-dashoffset: 705.17;
	}
	47.72%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-5 {
	animation: stroke-animation-5 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-5 {
	0%, 40.91% {
		stroke-dashoffset: 1129.86;
	}
	54.54%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-6 {
	animation: stroke-animation-6 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-6 {
	0%, 47.72% {
		stroke-dashoffset: 755.24;
	}
	61.36%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-7 {
	animation: stroke-animation-7 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-7 {
	0%, 54.54% {
		stroke-dashoffset: 859.21;
	}
	70.45%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-8 {
	animation: stroke-animation-8 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-8 {
	0%, 63.63% {
		stroke-dashoffset: 955.4;
	}
	79.54%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-9 {
	animation: stroke-animation-9 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-9 {
	0%, 72.72% {
		stroke-dashoffset: 299.87;
	}
	88.63%, 100% {
		stroke-dashoffset: 0;
	}
}

.stroke-10 {
	animation: stroke-animation-10 4400ms linear 1 normal forwards;
}

@keyframes stroke-animation-10 {
	0%, 81.81% {
		stroke-dashoffset: 1509.58;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
