.container {
	position: absolute;
	background: #000000;
	width: 45rem;
	height: 45rem;
	color: white;
	font-size: 1.9rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'FigTree Bold';
}

.qr-code {
	text-align: center;
}

.qr-code > img {
	width: 66%;
}

.top-bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	top: 1rem;
	bottom: 1rem;
	align-items: center;
}

.bottom {
	transform: rotate(180deg);
}

.left-right {
	display: flex;
	justify-content: space-between;
	position: absolute;
	left: 1rem;
	right: 1rem;
	height: 100%;
	align-items: center;
}

.right {
	writing-mode: vertical-rl;
}

.left {
	writing-mode: vertical-lr;
	transform: rotate(180deg);
}

.overlay {
	position: absolute;
	background: black;
	opacity: 0.4;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
