@font-face {
	font-family: 'FigTree';
	src: local('FigTree'), url('./fonts/Figtree/static/Figtree-SemiBold.ttf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'FigTree Bold';
	src: local('FigTree'), url('./fonts/Figtree/static/Figtree-Bold.ttf') format('truetype');
}


#root {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	font-family: 'FigTree';
}

.app-container {
	/* position: relative; */
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.chartContainer {
	position: relative;
	width: 100%;
	height: 100%;
}

svg {
	/* shape-rendering: crispEdges; */
	box-sizing: border-box;
}

aside {
	position: absolute;
	height: 800px;
	width: 300px;
	right: 50px;
	background: rgba(80, 80, 80, 0.7);
	backdrop-filter: blur(8px);
	border-radius: 8px;
	color: white;
	padding: 25px;
}

.legends-container {
	position: absolute;
    right: 2rem;
    top: 12%;
    bottom: 10%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
	height: 100%;
}

.legend {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column-reverse;
	padding: 1rem;
	margin: 0;
	background: rgba(28, 28, 28, 0.7);
	backdrop-filter: blur(2px);
	color: white;
}

.legend-lineLabel {
	display: flex;
	align-items: center;
}

.legend-lineLabel:last-of-type {
	padding-top: 0;
}

.legend-lineLabel:first-of-type {
	padding-bottom: 0;
}

.legend-lineLabel > div {
	color: #232323;
}

.placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}

.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: white;
}

.logo-container > .logo {
	object-fit: contain;
	width: 16%;
	padding: 1rem;
}

.placeholder-text {
	color: white;
	font-size: 1rem;
	font-weight: bold;
	padding: 2rem;
}

.notch {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10%;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	left: 0;
	top: 0;
	padding: 2rem;
}

.notch > .logo {
	width: 100%;
	margin-right: 2rem;
}

.status {
	position: fixed;
	bottom: 15px;
	right: 15px;
	height: 10px;
	width: 10px;
	background-color: white;
	border-radius: 50%;
}

.status--no-message, .status--no-optimization-found{
	background-color: #e74c3c;
}

.status--optimization-found {
	background-color: #2ecc71;
}

.logo {
	z-index: 1;
}

.chart {
  position: relative;
  width: 100%;
}

.MultilineChart,
.WeatherChart,
.CapacityChart {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: translateY(7%);
}

/* Different layout for screen res: 3840x1080 */
@media screen and (max-height: 1200px) {
	.app-container {
		position: relative;
		display: flex;
	}

	.chart {
		width: 85%;
	}

	.legends-container {
		position: static;
	}

	.notch {
		left: unset;
		top: 2rem;
		right: 0;
		width: 8%;
		padding: 0;
	}

	.notch img {
		scale: 0.8;
	}
}

@media screen and (width: 3840px) and (height: 1080px) {
	#root {
		padding: 1rem;
	}
	.app-container {
		position: relative;
		display: flex;
		/* gap: 8rem; */
	}

	.chart {
		width: 85%;
	}

	.legends-container {
		position: static;
	}

	.notch {
		left: unset;
		top: 2rem;
		right: 0;
		width: 8%;
		padding: 0;
	}

	.notch img {
		scale: 0.8;
	}
}